export const brandName = "QueuingMe";

/**
 * Restaurant URLs:
 * 
 * - Jejudo: https://www.queuingme.com/Jejudo/public-ip3b3t20/guest
 * - Toedam: https://www.queuingme.com/Toedam/public-ip3b3t20/guest
 * - Gooiga: https://www.queuingme.com/Gooiga/public-ip3b3t20/guest
 * - Yeunkyung: https://www.queuingme.com/Yeunkyung/public-ip3b3t20/guest
 * - MakchangDodook: https://www.queuingme.com/MakchangDodook/public-ip3b3t20/guest
 * - Otreat: https://www.queuingme.com/Otreat/public-ip3b3t20/guest
 * - QueuingMe: https://www.queuingme.com/QueuingMe/public-ip3b3t20/guest
 * - Midam: https://www.queuingme.com/Midam/public-ip3b3t20/guest
 * 
 * These are the base URLs for the restaurants' queue pages.
 */



/**
 * Restaurant URLs:
 * 
 * - Jejudo: https://www.queuingme.com/Jejudo/public-ip3b3t20/display
 * - Toedam: https://www.queuingme.com/Toedam/public-ip3b3t20/display
 * - Gooiga: https://www.queuingme.com/Gooiga/public-ip3b3t20/display
 * - Yeunkyung: https://www.queuingme.com/Yeunkyung/public-ip3b3t20/display
 * - MakchangDodook: https://www.queuingme.com/MakchangDodookAustin/public-ip3b3t20/display
 * - Otreat: https://www.queuingme.com/Otreat/public-ip3b3t20/display
 * - QueuingMe: https://www.queuingme.com/QueuingMe/public-ip3b3t20/display
 * - Midam: https://www.queuingme.com/Midam/public-ip3b3t20/display
 * 
 * These are the base URLs for the restaurants' queue pages.
 */
export const publicRoutes = "public-ip3b3t20";