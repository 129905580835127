import { LoadingButton } from "@mui/lab";
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import agent from "../../app/api/agent";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import { useAppSelector } from "../../app/store/configureStore";
import { useNavigate, useParams } from "react-router-dom";
import { displayForbiddenWords } from "../../app/configuration/forbiddenWords";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { phoneNumberConvertor } from "../../app/configuration/phoneNumberConvertor";
import {
    BBQrestaurants,
    reataurant_noalert,
    restaurant,
    restaurant_onehour,
    restaurant_twohours,
} from "../../app/configuration/restaurant";
import NotFound from "../errors/NotFound";
import { publicRoutes } from "../../app/configuration/globalConfig";


export default function GuestQueuePage() {
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.account);
    const [phone, setPhone] = useState("+1");
    const [btnLoading, setBtnLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
    });

    const handleChange = (newPhone: string) => {
        setPhone(() => {
            if (newPhone.length <= 2) return "+1";
            return newPhone;
        });
    };

    const alertMsg = () => {
        if (restaurant_onehour.includes(restaurantParam!.toLowerCase()))
            alert("Table time limit is 1h.\nSuccessfully added!")
        else if(restaurant_twohours.includes(restaurantParam!.toLowerCase()))
            alert("Table time limit is 2 hours.\nSuccessfully added!")
        else if(reataurant_noalert.includes(restaurantParam!.toLowerCase()))
            return "No alert.";
        else
            alert("Table time limit is 1h 30min.\nSuccessfully added!")
    };

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    if (!restaurant.includes(restaurantParam!.toLowerCase()))
        return <NotFound />;

    return (
        <Card
            style={{
                maxWidth: 450,
                margin: "10px auto",
                padding: "20px 5px",
            }}
            sx={{ boxShadow: 10 }}
        >
            <img
                src={`/images/${restaurantParam}Icon.png`}
                style={{ display: "block", margin: "auto", width: "20%" }}
                alt="logo"
            />
            <CardContent>
                <Box
                    component="form"
                    onSubmit={handleSubmit((data) => {
                        setBtnLoading(true);
                        if (data.size >= 15) {
                            alert("We don't accept size of 13 or more.");
                            return;
                        }
                        data.userName = displayForbiddenWords(data.userName);
                        data.phoneNumber = phoneNumberConvertor(
                            data.phoneNumber
                        );
                        data.restaurantName = restaurantParam;
                        agent.Display.create(data)
                            .then((response) => {
                                alertMsg();
                                !user &&
                                    navigate(
                                        `/${restaurantParam}/${publicRoutes}/guest/${response.id}`
                                    );
                                user?.roles?.includes("Admin") &&
                                    navigate(`/${restaurantParam}/admin`);
                            })
                            .catch((error) => console.error(error.response))
                            .finally(() => {
                                setBtnLoading(false);
                            });
                    })}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <Grid container spacing={1}>
                        <Grid xs={12} item>
                            <TextField
                                required
                                fullWidth
                                id="outlined-required"
                                label="Name"
                                placeholder="Jane"
                                margin="normal"
                                {...register("userName", {
                                    required: "Name is required",
                                    pattern: {
                                        value: /^([^0-9]*)$/,
                                        message:
                                            "Please not include any numbers",
                                    },
                                })}
                                error={!!errors.userName}
                                helperText={errors?.userName?.message?.toString()}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <MuiTelInput
                                required
                                fullWidth
                                id="outlined-number"
                                label="Phone number"
                                defaultCountry="CA"
                                autoComplete="do-not-autofill"
                                margin="normal"
                                {...register("phoneNumber", {
                                    required: "Phone is required",
                                    pattern: {
                                        value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, //eslint-disable-line
                                        message:
                                            "Valid format: +1 604 774 7985",
                                    },
                                })}
                                onChange={handleChange}
                                value={phone}
                                onlyCountries={["CA", "US"]}
                                error={!!errors.phoneNumber}
                                helperText={errors?.phoneNumber?.message?.toString()}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                required
                                fullWidth
                                id="outlined-number"
                                label="Size(Age 10+)"
                                type="number"
                                placeholder="0"
                                margin="normal"
                                {...register("size", {
                                    required: "Size is required",
                                    pattern: {
                                        value: /^([1-9]|1[0-4])$/,
                                        message:
                                            "Self check-in is available for up to a group of 14. If 15 or more, please reach out to a server.",
                                    },
                                })}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                }}
                                error={!!errors.size}
                                helperText={errors?.size?.message?.toString()}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                fullWidth
                                id="outlined-number"
                                label="Kids Size(Age 10-)"
                                type="number"
                                placeholder="0"
                                defaultValue={0}
                                margin="normal"
                                {...register("sizeKids", {
                                    pattern: {
                                        value: /^([0-9])$/,
                                        message:
                                            "We accept 0-9 Kids at a time.",
                                    },
                                })}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                }}
                                error={!!errors.sizeKids}
                                helperText={errors?.sizeKids?.message?.toString()}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                fullWidth
                                id="outlined-required"
                                label="Note"
                                placeholder="Add Message to the restaurant"
                                margin="normal"
                                {...register("note")}
                                error={!!errors.note}
                                helperText={errors?.note?.message?.toString()}
                            />
                        </Grid>

                        {BBQrestaurants.includes(
                            restaurantParam!.toLowerCase()
                        ) && (
                            <>
                                <Grid xs={12} item>
                                    <Box
                                        sx={{
                                            display: "block",
                                            margin: "auto",
                                            width: "25%",
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...label}
                                                        {...register("isBBQ")}
                                                    />
                                                }
                                                label={
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        width="50px"
                                                    >
                                                        <Grid xs={6} item>
                                                            <OutdoorGrillIcon
                                                                sx={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            BBQ
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </FormGroup>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Typography
                                        color={"red"}
                                        variant="body1"
                                        align="center"
                                    >
                                        Make sure to check the box if it's for BBQ
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        <Grid xs={12} item>
                            <LoadingButton
                                fullWidth
                                loading={btnLoading}
                                disabled={!isValid}
                                variant="contained"
                                type="submit"
                                sx={{
                                    fontWeight: 700,
                                    mt: 3,
                                    fontSize: "1.2rem",
                                }}
                            >
                                SUBMIT
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}
