import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { brandName } from "../configuration/globalConfig";

export default function StickyFooter() {
    const queryParams = new URLSearchParams(window.location.search);
    const rotate = queryParams.has('rotate');

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                zIndex: 99,
                display: rotate ? "none" : "flex", //TODO: Fix this to be dynamic
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                component="footer"
                sx={{
                    backgroundColor: "whitesmoke",
                    padding: "1rem",
                    width: "100%",
                }}
                textAlign="center"
            >
                <Container maxWidth="sm">
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        display="inline"
                    >
                        &nbsp;&nbsp;{"Copyright © 2023 - "}
                        {new Date().getFullYear() + ", "}
                    </Typography>

                    <Typography
                        variant="body1"
                        display="inline"
                        fontWeight={700}
                    >
                        <Link
                            href="https://www.queuingme.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {brandName}
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
}
