import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "./App";
import Register from "../../features/account/Register";
import AdminPage from "../../features/admin/AdminPage";
import DisplayPage from "../../features/guest/DisplayPage";
import GuestQueuePage from "../../features/guest/GuestQueuePage";
import Login from "../../features/account/Login";
import RequireAuth from "./RequireAuth";
import UpdateQueuePage from "../../features/guest/UpdateQueuePage";
import HistoryPage from "../../features/admin/HistoryPage";
import GuestQueueDetailsPage from "../../features/guest/GuestQueueDetailsPage";
import ErrorPage from "./ErrorPage";
import ErrorTestPage from "../../features/errorTest/ErrorTestPage";
import ServerError from "../../features/errors/ServerError";
import NotFound from "../../features/errors/NotFound";
import RestaurantCards from "../../features/restaurant/RestaurantCards";
import DisplayPageAPI from "../../features/guest/DisplayPageAPI";
import DisplayPageAPIMargin from "../../features/guest/DisplayPageAPI";
import DisplayPageAPIChromeCast from "../../features/guest/DisplayPageAPIChromeCast";
import Home from "./HomePage/Home";
import RequireParam from "./RequireParam";
import ProfilePage from "../../features/admin/ProfilePage";
import StatisticsPage from "../../features/admin/StatisticsPage";
import PasswordResetPage from "../../features/admin/AccountSetting/PasswordResetPage";
import CalendarPage from "../../features/admin/CalendarPage";
import DisplayActiveQueues from "../../features/admin/DisplayActiveQueues";
import TechnologyPage from "./HomePage/TechnologyPage";
import { publicRoutes } from "../configuration/globalConfig";

export const router = createBrowserRouter([
    {
        path: `/:restaurantParam`,
        element: <App />,
        // errorElement: <ErrorPage />,
        children: [
            //admin routes
            {
                element: <RequireAuth roles={['Admin', 'SuperAdmin']} />,
                children: [
                    { path: "admin", element: <AdminPage /> },
                    { path: "history", element: <HistoryPage /> },
                    // { path: "calendar", element: <CalendarPage /> },
                    { path: "statistics", element: <StatisticsPage /> },
                    { path: "profilePage", element: <ProfilePage /> },
                    { path: "passwordReset", element: <PasswordResetPage /> },
                    { path: "displayAll", element: <DisplayActiveQueues /> },
                ],
            },
            //public routes, restaurant parameter check
            {
                element: <RequireParam />,
                children: [
                    { path: "guest", element: <GuestQueuePage /> },
                    { path: "display", element: <DisplayPage /> },
                    { path: "displayAPI", element: <DisplayPageAPI /> },
                ],
            },
            //public routes
            // { path: "guest", element: <GuestQueuePage /> },
            { path: "guest/:id", element: <UpdateQueuePage /> },
            // { path: "display", element: <DisplayPage /> },
            // { path: "displayAPI", element: <DisplayPageAPI /> },
            { path: "login", element: <Login /> },
            { path: "register", element: <Register /> },
            { path: "error", element: <ErrorTestPage /> },
            { path: "server-error", element: <ServerError /> },
            { path: "not-found", element: <NotFound /> },
            { path: "", element: <TechnologyPage /> },
            {
                path: "*",
                element: <Navigate replace to={"/not-found"} />,
            },
        ],
    },
    //public routes without header
    {
        path: `:restaurantParam/${publicRoutes}`,
        children: [
            {
                path: "guest",
                element: <GuestQueuePage />,
            },
            {
                path: "guest/:id",
                element: <GuestQueueDetailsPage />,
            },
            {
                path: "display",
                element: <DisplayPage />,
            },
            {
                path: "displayAPI",
                element: <DisplayPageAPI />,
            },
            {
                path: "displayAPIChromeCast",
                element: <DisplayPageAPIChromeCast />,
            },
            {
                path: "displayAPIMargin",
                element: <DisplayPageAPIMargin />,
            },
        ],
    },
    {
        path: "restaurantCards",
        element: <RestaurantCards />,
    },

    { path: "server-error", element: <ServerError /> },
    { path: "login", element: <Login /> },
    { path: "register", element: <Register /> },
    { path: "not-found", element: <NotFound /> },
    // { path: "calendar", element: <Calendar /> },
    // { path: "calendar3", element: <Calendar3 /> },
    // onDateTimeSelected: (selectedDateTime: DateTime) => void;
    // {
    //     path: "calendar4",
    //     element: (
    //         <Calendar4
    //             label="Select a date and time"
    //             onDateTimeSelected={handleDateTimeSelected}
    //         />
    //     ),
    // },
    { path: "*", element: <Login /> },
    { path: "/", element: <Home /> },
]);

// export const router = createBrowserRouter([
//     ...jejudo, ...toedam,
//     { path: "not-found", element: <NotFound /> },
//     { path: "error", element: <ErrorTestPage /> },
//     { path: "server-error", element: <ServerError /> },
//     {
//         path: "*",
//         element: (
//             <Navigate replace to={"not-found"} />
//         ),
//     },
// ]);
