import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import displayStatus, { Queue, Status } from "../../app/models/queue";
import agent from "../../app/api/agent";
import { Box, Button, Typography } from "@mui/material";
import {
    StyledDashBoardTableCell,
    StyledDashBoardTableRow,
    dashBoardFontSize,
    dashBoardStatusFontSize,
} from "../../app/style/customStyle";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import { useLocation, useParams } from "react-router-dom";
import { restaurant } from "../../app/configuration/restaurant";
import NotFound from "../errors/NotFound";
import * as signalR from "@microsoft/signalr";
import queryString from "query-string";
import QRcode from "../../app/layout/partComponents/QRcode";
import withRotation from "../../app/layout/withRotation";
import { publicRoutes } from "../../app/configuration/globalConfig";

function DisplayPage() {
    const [queues, setQueues] = useState<Queue[]>([]);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [notification, setNotification] = useState("");
    const [loading, setLoading] = useState(true);
    const { pathname, search } = useLocation();
    const queryParams = queryString.parse(search);
    const bottomOffset = queryParams.bottom ? parseInt(queryParams.bottom as string) : 5;
    const rightOffset = queryParams.right ? parseInt(queryParams.right as string) : 3;
    const qrCodeSize = queryParams.size ? parseInt(queryParams.size as string) : 20;
    const isRotated = queryParams.rotate !== undefined;
    const isPublicPath = pathname.includes(`/${publicRoutes}/`);

    useEffect(() => {
        agent.Display.list(restaurantParam!).then((queues) => {
            setQueues(queues);
            setLoading(false);
        });
        // signalR connection
        const connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl("/DbUpdateHub", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .build();

        connection.start();

        connection.on("ReceiveNotification", (rest, msg) => {
            if (rest.toLowerCase() === restaurantParam?.toLowerCase())
                setNotification(msg);
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        agent.Display.list(restaurantParam!).then((queues) => {
            setQueues(queues);
        });
    }, [notification]); //eslint-disable-line

    useEffect(() => {
        const interval = setInterval(() => {
            agent.Display.list(restaurantParam!).then((queues) => {
                setQueues(queues);
            });
        }, 1800000); // 30 minutes

        return () => clearInterval(interval);
    }, []);

    if (loading) return <LoadingComponent message="Loading..." />;

    if (!restaurant.includes(restaurantParam!.toLowerCase())) {
        return <NotFound />;
    }

    return (
        <div style={{ display: "block", margin: "40px" }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    backgroundColor: "white",
                    borderRadius: "10px 10px 0 0",
                    minHeight: "80px",
                }}
            >
                <img
                    src={`/images/${restaurantParam}Icon.png`}
                    style={{
                        maxHeight: "90px",
                        padding: "5px",
                        maxWidth: "15%",
                        margin: "5px",
                    }}
                    alt="logo"
                />
                <Typography
                    sx={{ fontSize: dashBoardFontSize }}
                    fontWeight="900"
                >
                    Waiting List
                </Typography>
            </Box>

            <TableContainer
                component={Paper}
                sx={{ borderRadius: "0 0 10px 10px" }}
            >
                <Table sx={{ width: "100%" }} aria-label="a simple table">
                    <TableHead sx={{ height: "80px" }}>
                        <TableRow>
                            <StyledDashBoardTableCell>
                                Queue
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Name
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell
                                sx={{
                                    display: {
                                        "@media (max-height: 1000px)": {
                                            display: isRotated
                                                ? "none"
                                                : "table-cell",
                                        },
                                    },
                                }}
                            >
                                Phone Number
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Size
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Status
                            </StyledDashBoardTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queues.map((row, idx) => (
                            <StyledDashBoardTableRow
                                key={row.id.toString()}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <StyledDashBoardTableCell
                                    component="th"
                                    scope="row"
                                >
                                    {idx + 1}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {row.userName.split(" ")[0]}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell
                                    sx={{
                                        display: {
                                            "@media (max-height: 1000px)": {
                                                display: isRotated
                                                    ? "none"
                                                    : "table-cell",
                                            },
                                        },
                                    }}
                                >
                                    {displayPhoneNumber(row.phoneNumber)}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {row.size}{" "}
                                    {(row.sizeKids != 0 ||
                                        row.sizeKids == null) &&
                                        " + " + row.sizeKids}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    <Button
                                        variant={
                                            row.status === Status.Waiting
                                                ? "outlined"
                                                : "contained"
                                        }
                                        color={
                                            row.status === Status.Ready
                                                ? "success"
                                                : "primary"
                                        }
                                        sx={{
                                            minWidth: "200px",
                                            width: "250px",
                                            fontSize: dashBoardStatusFontSize,
                                        }}
                                    >
                                        {displayStatus(row.status)}
                                    </Button>
                                </StyledDashBoardTableCell>
                            </StyledDashBoardTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPublicPath && (
                <QRcode
                    restaurantName={restaurantParam!}
                    bottom={bottomOffset}
                    right={rightOffset}
                    size={qrCodeSize}
                />
            )}
        </div>
    );
}

export default withRotation(DisplayPage);
